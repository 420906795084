<template>
  <ComponentLazy v-slot="{ isVisible }" ref-name="trustpilotWidget" :height="20">
    <div
      v-if="isVisible"
      ref="trustpilotWidget"
      class="trustpilot-widget"
      :data-locale="langCode"
      :data-template-id="dataTemplateId"
      :data-businessunit-id="country.trustpilotDomainID"
      :data-style-height="dataStyleHeight"
      :data-style-width="dataStyleWidth"
      :data-theme="dataTheme"
      :data-stars="dataStars"
      :data-review-languages="locale"
      :data-test-id="elementTest"
      :data-style-alignment="dataStyleAlignment"
    >
      <a
        v-if="withLink"
        :href="country?.trustpilotLink"
        target="_blank"
        rel="noopener"
        :data-test-id="elementTestLink"
      />
    </div>
  </ComponentLazy>
</template>

<script setup>
import { useI18nStore } from '@/stores/i18nStore'
import { useClubStore } from '@/stores/club'

const i18nStore = useI18nStore()
const langCode = i18nStore.langCode(true)

const { country, locale } = useCurrentLocale()

const isClub = computed(() => {
  const clubStore = useClubStore()
  return clubStore.isClub
})

const trustpilotWidget = ref(null)

defineProps({
  dataStyleHeight: { type: String, default: '240px' },
  dataStyleWidth: { type: String, default: '100%' },
  dataStyleAlignment: { type: String, default: '' },
  dataTheme: { type: String, default: 'light' },
  dataStars: { type: String, default: '3,4,5' },
  dataTemplateId: { type: String, default: '' },
  elementTest: { type: String, default: 'trustpilot' },
  elementTestLink: { type: String, default: 'trustpilot-link-not-connected' },
  withLink: { type: Boolean, default: false },
})

watch(
  () => trustpilotWidget.value,
  () => {
    loadTrustpilotWidget()
  }
)
onMounted(() => {
  if (!isClub.value) {
    nextTick(() => loadTrustpilotWidget())
  }
})

const loadTrustpilotWidget = () => {
  const trustpilotScript = document.getElementById('trustpilot-widget-script')
  if (!trustpilotScript) {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.id = 'trustpilot-widget-script'
    script.src = 'https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js'
    script.async = true
    document.head.appendChild(script)
    nextTick(() => loadTrustpilotFromElement())
  } else {
    nextTick(() => loadTrustpilotFromElement())
  }
}

const loadTrustpilotFromElement = () => {
  if (window.Trustpilot && trustpilotWidget.value) {
    window.Trustpilot.loadFromElement(trustpilotWidget.value, true)
  }
}
</script>
